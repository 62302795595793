<style scoped>
.v-sheet.v-card {border-top-left-radius: 6px !important;border-top-right-radius: 6px !important;}
.aligncard.v-sheet.v-card{border-radius: 4px !important;}
.greencolor {background-color: #D6F6F0 !important;}
</style>

<template>
  <div>
    <v-flex xs12 class="pa-2 pt-0">
      <v-container fluid>
        <v-layout row wrap align-start justify-start>
          <v-flex xs12 sm12 md12 lg12 xl12 class="pa-0 pt-0">
            <div>
            <v-card class="pa-2 a-box" height="58">
              <div class="text-center mt-4">The next trading holiday</div>
            </v-card>
            </div>
          </v-flex> 
           </v-layout>
          </v-container>
              <div class="mt-2"></div>
              <v-card class="mt-1 aligncard mx-auto mb-0" elevation="" flat  v-for="(item, index) in holidayCalendar" :key="index">
                    <v-card-text class="py-2"> 
                    <v-layout align-center>
                      <v-flex xs2></v-flex>
                      <v-flex xs2>
                       <div>{{item.holidayDate}}</div>
                       <div>{{item.holidayDay}}</div>
                      </v-flex>
                      <v-flex xs5><p class="text-xs-left mb-0"><b>{{item.holidayName}}</b></p></v-flex>
                      <v-flex wrap xs4>
                        <div class="text-xs-right" style="">
                        <v-btn :ripple="false" text-lowercase x-small v-if="item.tradetype !=''" class="ml-0 pa-2  mb-0" outlined color="#2EA6F5">TRADING</v-btn>
                        <span class="ml-1"><v-btn :ripple="false" x-small  class="ml-0 pa-2"  outlined color="#7E7E7E" style="border-color: #E1E1E1;">SETTLEMENT</v-btn></span>
                        </div> 
                        <div class="mt-1"><span>NSE</span><span class="ml-3">BSE</span><span class="ml-3">MCX</span></div>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
              </v-card>
    </v-flex>
  </div>
</template>
<script>

import { mapState } from "vuex"

export default {
  components: {
  
  },
  
  data() {
    return {
      holidaydata:[],
      option: {},
      
    };
  },
  methods:{
   
  },

   computed:{

      ...mapState('calendars',['holidayCalendar']),

      holiday(){
        return this.$store.state.holidayCalendar
      }

    },

  created(){
     this.$store.dispatch('calendars/holidayData')
  },

};
</script>

