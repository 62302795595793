<template>
  <div>
    <v-container fluid grid-list-xl>
        <v-layout row wrap align-start justify-start>
           <v-flex xs12 sm12 md6 lg6 xl6 class="pa-2">
                <v-card class="a-box pa-0" width="fit-content">
            <span class="grey">
              <v-tabs v-model="tab" height="36" class="horizontal-tab tabsegment" tag="button">
                  <v-tab height="28" color="#ffffff"  @click="tabsChange('eventcalendar')">Event Calendar</v-tab>
                  <v-tab height="28" color="#ffffff"  @click="tabsChange('resultcalendar')">Result Calendar</v-tab>
                  <v-tab height="28" color="#ffffff"  @click="tabsChange('holidaycalendar')">Holiday Calendar</v-tab>
              </v-tabs>
            </span>
           </v-card>
           </v-flex>
        </v-layout>
    </v-container>
    <div v-if="eventcalendar">
      <eventcalender />
    </div>
    <div v-if="resultcalendar">
      <resultcalender />
    </div>
    <div v-if="holidaycalendar">
      <holidaycalender />
    </div>
  </div>
</template>

<script>
import eventcalender from "@/views/calendaroptions/eventcalender.vue";
import Holidaycalender from "@/views/calendaroptions/holidaycalender.vue";
import Resultcalender from "@/views/calendaroptions/resultcalender.vue";
export default {
  components: { eventcalender, Resultcalender, Holidaycalender },
  data() {
    return {
      eventcalendar: true,
      resultcalendar: false,
      holidaycalendar: false,
      tab: 0,
    };
  },
  
  methods: {
    tabreset() {
      this.eventcalendar = this.resultcalendar = this.holidaycalendar = false;
    },

    tabsChange(selectedTab){
      this.tabreset()
      this[selectedTab] = true ;
      selectedTab == 'eventcalendar' ? this.tab = 0 : selectedTab == 'resultcalendar' ? this.tab = 1 
      : selectedTab == 'holidaycalendar' ? this.tab = 2 : this.tab = 0;
      this.$router.push({ path: this.$router.history.current.fullPath, query: { tab: selectedTab } }).catch(() => {});
    },
  },

  mounted() {
    var currentTab = this.$route.query.tab ;
    currentTab ? this.tabsChange(currentTab) : ''
  }
};
</script>
