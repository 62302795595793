<style scoped>


</style>

<template>
  <div>
    <v-flex xs12 class="pa-0 pt-0">
      <v-container fluid>
        <v-layout row wrap align-start justify-start>
          <v-flex xs12 sm12 md12 lg12 xl12 class="pa-2 pt-0">
            <v-card class="pa-2 a-box">
              <div class="text-center mt-4 title">Event Calender</div>
              <v-chart class="chart" autoresize :option="option" />
            </v-card>
          </v-flex>
          </v-layout
      ></v-container>
    </v-flex>
  </div>
</template>
<script>
import VChart, {} from "vue-echarts";
import "@/plugins/echart";
export default {
  components: {
  VChart
  },

  data() {
    return {
      option: {
        
      },
    };
  },
};
</script>

